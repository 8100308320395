<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <ul v-if="completeList.length > 0" class="divide-y divide-gray-100">
    <li
      @click="viewProfile(person.user_id)"
      v-for="person in completeList"
      :key="person.company"
      class="p-4 flex"
    >
      <div class="relative">
        <img class="h-10 w-10 rounded-full" :src="person.photo" alt="" />
        <span
          v-if="person.favourite === 1"
          class="badge badge-danger badge-pill favorite_icon"
        >
          <StarIcon />
        </span>
      </div>
      <div class="ml-3">
        <p class="text_username">{{ person.company }}</p>
        <p class="text-sm text-main-light">{{ person.role }}</p>
      </div>
    </li>
  </ul>
  <div class="text-center">
    <span
      v-if="completeList.length === 0"
      class="text-center text-sm"
      style="color: #727497 !important"
      >You have not previously worked for
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { APIgetemployedby } from "@/api/candidate/api";
import { StarIcon } from "@heroicons/vue/solid";

export default {
  data() {
    return {
      people: [],
      completeList: [],
    };
  },
  components: {
    StarIcon,
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
      fetchSetEmployerModalConfigs: "candidate/fetchSetEmployerModalConfigs",
    }),
    viewProfile(userid) {
      this.fetchSetEmployerModalConfigs({ user_id: userid });
      const data = {
        isOpen: true,
        iconName: "UserIcon",
        componentName: "EmployerProfile",
        title: "",
        data: { modal_type: "base" },
      };
      this.fetchtSetModalConfigs(data);
    },
  },
  mounted() {
    APIgetemployedby().then((response) => {
      this.completeList = response.data.results;
      console.log("aaa", this.completeList);
    });
  },
};
</script>
