<template>
  <div>
    <h3 class="text-main text-xl mb-4 text-center Biotif-bold text-header">
      Your Notifications:
    </h3>
    <div
      v-for="task in task_group"
      :key="task.id"
      :task="task"
      class="flex mb-4 py-2 px-2 rounded-md shadow-around-sm task_div"
      :class="task.relative_task"
    >
      <ClipboardCheckIcon
        v-if="task.relative_task === 'timesheet'"
        class="w-5 mr-1 self-center"
      />
      <CalendarIcon
        v-if="task.relative_task === 'shift'"
        class="w-5 mr-1 self-center"
      />
      <CashIcon
        v-if="task.relative_task === 'wage'"
        class="w-5 mr-1 self-center"
      />
      <StarIcon
        v-if="task.relative_task === 'review'"
        class="w-5 mr-1 self-center"
      />
      <UserIcon
        v-if="task.relative_task === 'system'"
        class="w-5 mr-1 self-center"
      />

      <p
        class="text-sm w-52 lg:w-72 sm:text-sm text-main-light self-center"
        style="color: #727497"
      >
        {{ task.task }}
      </p>
      <p
        class="
          task_action
          tracking-wide
          text-sm text-emerald
          font-bold
          self-center
          cursor-pointer
          ml-auto
        "
        @click="action(task)"
      >
        {{ task.action }}
      </p>
    </div>
    <div class="text-center">
      <span
        v-if="taskGroup.length === 0"
        class="text-center text-sm"
        style="color: #727497 !important"
        >You have no new tasks</span
      >
    </div>
    <div
      v-if="taskGroup.length > 0"
      class="
        text-center
        pt-1
        pb-4
        cursor-pointer
        text-green-500
        hover:text-emerald
      "
    >
      <button
        @click="ChangeMoreFlag"
        v-if="more_flag && taskGroup.length > 3"
        class="font-bold"
      >
        View all tasks ({{ taskGroup.length }})
      </button>
      <button @click="ChangeMoreFlag" v-if="!more_flag" class="font-bold">
        Show less tasks
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  APIdoChangeSubscription,
  APIdoGetSubscriptionActive,
  APIdelTask,
} from "@/api/common/api";
import {
  ClipboardCheckIcon,
  CalendarIcon,
  StarIcon,
  UserIcon,
  CashIcon,
} from "@heroicons/vue/solid";

import { APIfetchEmployerTimeSheetByID } from "@/api/employer/api";

import { APIfetchCandidateTimeSheetByID } from "@/api/candidate/api";

export default {
  components: {
    ClipboardCheckIcon,
    CalendarIcon,
    CashIcon,
    UserIcon,
    StarIcon,
  },
  computed: {
    ...mapGetters({
      user_detail: "authentication/getUserDetails",
      taskGroup: "candidate/getTaskList",
    }),
  },
  data: () => ({
    task_group: [],
    more_flag: true,
    task_obj: {},
  }),
  watch: {
    taskGroup: function () {
      this.updateHandler();
    },
  },
  mounted() {
    this.FetchgetTaskList();
    this.updateHandler();
  },
  methods: {
    ...mapActions({
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
      FetchgetTaskList: "candidate/FetchgetTaskList",
    }),
    ChangeMoreFlag() {
      this.more_flag = !this.more_flag;
      this.updateHandler();
    },

    updateHandler() {
      console.log(this.task_group, "aaa");
      if (this.more_flag && this.taskGroup.length > 3) {
        let a = [];
        for (var i = 0; i < 3; i++) {
          a.push(this.taskGroup[i]);
        }
        this.task_group = a;
      } else {
        this.task_group = this.taskGroup;
      }
    },
    action(task) {
      if (task.task === "Set up a subscription") {
        const data = {
          isOpen: true,
          iconName: "TicketIcon",
          componentName: "SubscriptionModalContent",
          title: "",
          data: { modal_type: "small", closeFlag: true },
        };
        this.fetchtSetModalConfigs(data);
      } else if (task.task === "Set up direct debit") {
        this.loader_flag = true;
        let post_data = {
          action: "session",
        };
        APIdoChangeSubscription(post_data).then((res) => {
          this.loader_flag = false;
          if (res.data.success) {
            window.location.href = res.data.object.url;
          }
        });
      } else if (task.task === "Update your subscription") {
        const data = {
          isOpen: true,
          iconName: "TicketIcon",
          componentName: "SubscriptionModalContent",
          title: "",
          data: { modal_type: "small", action_type: "change", closeFlag: true },
        };
        this.fetchtSetModalConfigs(data);
      } else if (task.task === "Create a shift") {
        APIdoGetSubscriptionActive().then((res) => {
          if (res.data.results[0].compliance_complete === 0) {
            const data = {
              isOpen: true,
              iconName: "TicketIcon",
              componentName: "InactiveComplianceModalContent",
              title: "",
              data: { modal_type: "small" },
            };
            this.fetchtSetModalConfigs(data);
          } else if (res.data.results[0].sub_status === 0) {
            const data = {
              isOpen: true,
              iconName: "TicketIcon",
              componentName: "InactiveSubscriptionModalContent",
              title: "",
              data: { modal_type: "small" },
            };
            this.fetchtSetModalConfigs(data);
          } else {
            const data = {
              isOpen: true,
              iconName: "ClipboardCheckIcon",
              componentName: "AddShiftModalContent",
              title: "",
              data: { modal_type: "base" },
            };
            this.fetchtSetModalConfigs(data);
          }
        });
      } else {
        if (task.action === "review") {
          const data = {
            isOpen: true,
            iconName: "ClipboardCheckIcon",
            componentName: "ReviewModal",
            title: "",
            data: {
              modal_type: "small",
              user_id: task.link,
              shift_id: task.shift_id,
            },
          };
          this.fetchtSetModalConfigs(data);
        } else if (task.action === "dismiss") {
          APIdelTask(task.id).then((res) => {
            console.log(res);
            this.FetchgetTaskList();
          });
        } else if (task.action === "view") {
          let type =
            this.user_detail.user_type === 3 ? "employer" : "candidate";
          window.location.href = "#/" + type + task.link;
          APIdelTask(task.id).then((res) => {
            console.log(res);
            this.FetchgetTaskList();
          });
        } else if (task.action === "read") {
          let type =
            this.user_detail.user_type === 3 ? "employer" : "candidate";
          window.location.href = "#/" + type + task.link;
          APIdelTask(task.id).then((res) => {
            console.log(res);
            this.FetchgetTaskList();
          });
        } else if (
          task.action === "submit" ||
          task.action === "approve" ||
          task.action === "confirm"
        ) {
          if (task.type === "candidate") {
            APIfetchCandidateTimeSheetByID(task.ts_id).then((res) => {
              console.log(res);
              this.task_obj = res.data.object;
              if (task.ts_status === "c_waiting") {
                const data = {
                  isOpen: true,
                  iconName: "ClipboardCheckIcon",
                  componentName: "CandidateSubmitTimeSheetModalContent",
                  title: "",
                  data: { modal_type: "small", timesheet: this.task_obj },
                };
                this.fetchtSetModalConfigs(data);
              } else {
                const data = {
                  isOpen: true,
                  iconName: "ClipboardCheckIcon",
                  componentName: "CandidateAcceptTimeSheetModalContent",
                  title: "",
                  data: { modal_type: "small", timesheet: this.task_obj },
                };
                this.fetchtSetModalConfigs(data);
              }
            });
          }
          if (task.type === "employer") {
            APIfetchEmployerTimeSheetByID(task.ts_id).then((res) => {
              this.task_obj = res.data.object;
              if (task.ts_status === "e_waiting") {
                const data = {
                  isOpen: true,
                  iconName: "ClipboardCheckIcon",
                  componentName: "EmployerSubmitTimeSheetModalContent",
                  title: "",
                  data: {
                    modal_type: "small",
                    timesheet: this.task_obj,
                    user_type: "employer",
                  },
                };
                this.fetchtSetModalConfigs(data);
              }
            });
          }
        } else {
          let type =
            this.user_detail.user_type === 3 ? "employer" : "candidate";
          window.location.href = "#/" + type + task.link;
        }
      }
    },
  },
};
</script>
